class ScormService {
  private _scormsProxyAddress = process.env.VUE_APP_SCORMS_PROXY_ADDRESS || ''

  private _scormsBucketAddress = process.env.VUE_APP_SCORMS_BUCKET_ADDRESS || ''

  private _scormsStagingProxyAddress = process.env.VUE_APP_SCORMS_STAGING_PROXY_ADDRESS || ''

  private _scormsStagingBucketAddress = process.env.VUE_APP_SCORMS_STAGING_BUCKET_ADDRESS || ''

  getScormsDownloadAddress(url: string) {
    if (url.startsWith(this._scormsStagingBucketAddress)) {
      return url.replace(this._scormsStagingBucketAddress, this._scormsStagingProxyAddress);
    } if (url.startsWith(this._scormsBucketAddress)) {
      return url.replace(this._scormsBucketAddress, this._scormsProxyAddress);
    }
    throw new Error(`Not supported scorms bucket url: ${url}`);
  }
}
const scormService = new ScormService();
export default scormService;
