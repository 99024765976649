
import {
  defineComponent, PropType, ref,
} from 'vue';
import { useEscKeyDown, useRef } from '@/composables';
import { ScormApi } from './Scorm';
import { ScormVersion } from './types';
import ScormService from '@/services/ScormService';
import { isElement } from '@/helpers';

interface IScormViewData {
  isLoaded: boolean;
  localSrc?: string;
}

/**
 * События прослушивания скорма:
 *
 *
 * onUpdate:
 * @param {string} data - Состояние Скорма
 *
 *
 * onChangeStatus:
 * @param {string} status - Статус прохождения скорма
 *
 *
 * onMounted:
 * Вызывается при инициализации скорма
 */
export default defineComponent({
  name: 'ScormIFrame',

  props: {
    version: {
      type: String as PropType<ScormVersion>,
      required: true,
    },

    meta: {
      type: String as PropType<string>,
      default: '{}',
    },

    src: {
      type: String as PropType<string>,
      required: true,
    },

    isToolbarVisible: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const isFullscreen = ref(false);

    const styles = ref<{
      zIndex: number;
      position: 'fixed' | 'absolute';
      top: string;
      bottom: string;
      left: string;
      right: string;
      width: string;
      height: string;
      transition: string;
    }>({
      zIndex: 1,
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      width: 'initial',
      height: 'initial',
      transition: 'none',
    });

    const [container, setContainer] = useRef();
    const [wrapper, setWrapper] = useRef();

    const getTargetSize = () => {
      if (!isElement(wrapper.value)) {
        return {
          top: 0,
          left: 0,
          width: 0,
          height: 0,
        };
      }

      const rect = wrapper.value.getBoundingClientRect();

      return {
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
      };
    };

    const enableFullscreen = () => {
      isFullscreen.value = true;

      const targetSize = getTargetSize();

      styles.value = {
        position: 'fixed',
        top: `${targetSize.top}px`,
        bottom: 'auto',
        height: `${targetSize.height}px`,
        left: `${targetSize.left}px`,
        right: 'auto',
        width: `${targetSize.width}px`,
        transition: 'none',
        zIndex: 1,
      };

      requestAnimationFrame(() => {
        styles.value = {
          position: 'fixed',
          top: '0px',
          bottom: '0px',
          height: '100vh',
          left: '0px',
          right: '0px',
          width: '100vw',
          transition: 'top .2s ease-in-out, height .2s ease-in-out, left .2s ease-in-out, width .2s ease-in-out',
          zIndex: 999,
        };
      });
    };

    const disableFullscreen = () => {
      isFullscreen.value = false;

      if (!container.value) return;

      const targetSize = getTargetSize();

      styles.value = {
        position: 'fixed',
        top: `${targetSize.top}px`,
        bottom: 'initial',
        height: `${targetSize.height}px`,
        left: `${targetSize.left}px`,
        right: 'initial',
        width: `${targetSize.width}px`,
        transition: 'top .2s ease-in-out, height .2s ease-in-out, left .2s ease-in-out, width .2s ease-in-out',
        zIndex: 999,
      };
    };

    const toggleFullscreen = () => {
      if (isFullscreen.value) {
        disableFullscreen();
      } else {
        enableFullscreen();
      }
    };

    const onPressEscapeHandler = () => {
      if (!isFullscreen.value) return;

      disableFullscreen();
    };

    const onFullscreenClickHandler = () => {
      toggleFullscreen();
    };

    const onTransitionEndHandler = () => {
      if (isFullscreen.value) return;

      requestAnimationFrame(() => {
        styles.value = {
          position: 'absolute',
          top: '0px',
          bottom: '0px',
          height: 'initial',
          left: '0px',
          right: '0px',
          width: 'initial',
          transition: 'none',
          zIndex: 1,
        };
      });
    };

    useEscKeyDown(onPressEscapeHandler);

    return {
      isFullscreen,
      setContainer,
      setWrapper,
      styles,
      disableFullscreen,
      toggleFullscreen,
      onFullscreenClickHandler,
      onTransitionEndHandler,
    };
  },

  data(): IScormViewData {
    return {
      isLoaded: false,
      localSrc: undefined,
    };
  },

  watch: {
    content: {
      immediate: true,
      handler() {
        this.scormInit();
      },
    },
  },

  methods: {
    async scormInit() {
      this.isLoaded = true;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const scorm = new ScormApi({
        version: this.version,
        meta: this.meta,
        update: (data: string) => this.update(data),
        initialize: () => this.initialize(),
        changeStatus: (status: string) => this.changeStatus(status),
        apiLoaded: this.apiLoaded,
      });
    },

    async update(data: string) {
      this.$emit('update', data);
    },

    async initialize() {
      this.rewriteScormIframeHistoryMethods();
      this.$emit('initialize');
    },

    async changeStatus(status: string) {
      this.$emit('change:status', status);
    },

    apiLoaded() {
      this.localSrc = ScormService.getScormsDownloadAddress(this.src);
      this.isLoaded = true;
    },

    rewriteScormIframeHistoryMethods() {
      const scormIframeElement: HTMLIFrameElement = this.$refs.scormIframe as HTMLIFrameElement;
      if (scormIframeElement.contentWindow) {
        scormIframeElement.contentWindow.history.pushState = new Proxy(
          scormIframeElement.contentWindow.history.pushState, {
            apply: () => {},
          },
        );

        scormIframeElement.contentWindow.history.replaceState = new Proxy(
          scormIframeElement.contentWindow.history.replaceState, {
            apply: () => {},
          },
        );
      }
    },
  },
});
