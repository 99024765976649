/* eslint-disable max-classes-per-file */
// eslint-disable-next-line import/prefer-default-export
import { PlayerSessionId, PlayerSessionStatus } from '../common';

export enum CreationState {
  INITIAL= 'INITIAL',
  UPLOADING = 'UPLOADING',
  UNPACKING = 'UNPACKING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export enum ScormPackageStatus {
  NEW = 'new',
  PROCESSING = 'processing',
  INVALID = 'invalid',
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  DELETED = 'deleted'
}

export enum ScormProgressStatus {
  PUBLISHED = 'published',
  COMPLETED = 'completed',
  PASSED = 'passed'
}

export enum ScormVersion {
  AICC = 'aicc',
  Scorm12API = 'scorm12',
  Scorm2004API = 'scorm2004_4',
}

export interface IScormPackage {
  id: UUID;
  status: ScormPackageStatus;
  schemaId: number | null;
  schemaVersion: ScormVersion | null;
  title: string | null;
  duration: string | null;
  resourceFiles: string | null;
  originFileName: string | null;
  originFileSize: number | null;
  originFileExt: string | null;
  originFileMime: string | null;
  src: string | null;
}

export interface IScormOwner {
  id: string;
  name: string;
}

export interface IScormAuthor {
  id: string;
  fullName: string;
}

export interface IScormProgress {
  playerSessionId: PlayerSessionId;
  status: PlayerSessionStatus;
  progressMeta: string | null; // JSON as string
}

export interface IScormOptions {
  logLevel: number
}

export interface IScormAgainApi {
  cmi: object;

  loadFromJSON: (json: object) => void

  on: (listenerName: string, callback: () => void) => void,

  clear: (listenerName: string) => void
}

abstract class BaseScorm {
  constructor(options: IScormOptions) {
    this.logLevel = options.logLevel || 5;
  }

  cmi: object | null = null

  loadFromJSON: (json: object) => void = () => null

  on: (listenerName: string, callback: () => void) => void = () => null

  clear: (listenerName: string) => void = () => null

  logLevel: number
}

export class Scorm12API extends BaseScorm {
  // eslint-disable-next-line no-useless-constructor
  constructor(options: IScormOptions) {
    super(options);
  }
}

export class Scorm2004API extends BaseScorm {
  // eslint-disable-next-line no-useless-constructor
  constructor(options: IScormOptions) {
    super(options);
  }
}

export class AICC extends BaseScorm {
  // eslint-disable-next-line no-useless-constructor
  constructor(options: IScormOptions) {
    super(options);
  }
}

export class ScormBroadcaster {
  // TODO: При необходимости добавить доступные события
  public static post(event: 'scormCompleted') {
    const channel = new MessageChannel(); // Обязательно новый, иначе не получится отправить больше 1 сообщения!
    window.postMessage(event, '*', [channel.port2]);
  }
}
