/* eslint-disable import/prefer-default-export */
import transport from '@/services/api/lxp-scorm/transport';
import * as methods from '@/services/api/constants';
import {
  IScormCheckNewVersionResponse,
  IScormSessionCompleteResponse,
  IScormSessionGetResponseData, IScormSessionUpdateRequestPayload, IScormSessionUpdateResponse,
} from './types';
import { PlayerSessionId } from '@/domains/common';

/**
 * @url https://apidocs.talenttechlab.org/sources/167978#/scorms/getV3ScormsPlayerPlayerSessionId
 */
export const scormSessionGet = async (
  { playerSessionId }: { playerSessionId: PlayerSessionId },
): Promise<IScormSessionGetResponseData> => transport({
  url: `/v3/scorms/player/${playerSessionId}`,
  method: methods.HTTP_GET,
});

/**
 * @url https://apidocs.talenttechlab.org/sources/167978#/scorms/postV3ScormsPlayerPlayerSessionIdProgress
 */
export const scormSessionUpdate = async (
  { playerSessionId, payload }: { playerSessionId: PlayerSessionId, payload: IScormSessionUpdateRequestPayload},
): Promise<IScormSessionUpdateResponse> => transport({
  url: `/v3/scorms/player/${playerSessionId}/progress`,
  method: methods.HTTP_POST,
  data: payload,
});

/**
 * @url https://apidocs.talenttechlab.org/sources/167978#/scorms/postV3ScormsPlayerPlayerSessionIdComplete
 */
export const scormSessionComplete = async (
  { playerSessionId }: { playerSessionId: PlayerSessionId },
): Promise<IScormSessionCompleteResponse> => transport({
  url: `/v3/scorms/player/${playerSessionId}/complete`,
  method: methods.HTTP_POST,
});

/**
 * @url https://apidocs.talenttechlab.org/sources/192461#/scorms/getV3ScormsPlayerPlayerSessionIdCheckVersion
 *
 */
export const scormCheckNewVersion = async (
  { playerSessionId }: { playerSessionId: PlayerSessionId },
): Promise<IScormCheckNewVersionResponse> => transport({
  url: `/v3/scorms/player/${playerSessionId}/check_version`,
  method: methods.HTTP_GET,
});
