import 'scorm-again';
import { IScormOptions, ScormVersion } from './types';

export interface IScormApiAttrs {
  version: ScormVersion,
  meta?: string,
  options?: IScormOptions,
  update?: (meta: string) => void,
  initialize?: () => void,
  apiLoaded?: () => void,
  changeStatus?: (status: string) => void,
}

const defaultOptions = {
  logLevel: 1,
};

export class ScormApi {
  constructor({
    version,
    meta = '{}',
    options = defaultOptions,
    update = () => ({}),
    initialize = () => ({}),
    apiLoaded = () => ({}),
    changeStatus = () => ({}),
  }: IScormApiAttrs) {
    this.version = version;
    this.options = options;
    this.meta = meta;
    this.update = update;
    this.apiLoaded = apiLoaded;
    this.initialize = initialize;
    this.changeStatus = changeStatus;

    this.init(meta, options);
  }

  /**
   * Версия скорма (AICC, 12 , 2004)
   */
  version: ScormVersion;

  /**
   *  опции scorm-again для инициализации скорма
   */
  options: IScormOptions;

  /**
   * Состояние/прогресс прохождения скорма: JSON формат
   */
   meta: string;

   /**
   *  Слушатель обновления скорма
   */
   update: (meta: string) => void;

   /**
   *  Слушатель обновления статуса скорма
   */
   changeStatus: (status: string) => void;

   /**
   *  Слушатель инициализации скорма после установки в iframe
   */
   initialize: () => void;

   /**
   *  Слушатель инициализации API скорма
   */
    apiLoaded: () => void;

    /**
   * Общий метод инициализации СКОРМА
   */
    init(meta: string, options: IScormOptions) {
      switch (this.version) {
        case ScormVersion.AICC:
          this.initScormAICC(meta, options);
          break;
        case ScormVersion.Scorm12API:
          this.initScorm12API(meta, options);
          break;
        case ScormVersion.Scorm2004API:
          this.initScorm2004(meta, options);
          break;
        default:
          throw new Error('Scorm api type not supported');
      }
    }

    /**
   * Инициализация Скорма 2004 версии
   */
    private initScorm2004(meta: string, options: IScormOptions) {
      window.API_1484_11 = new Scorm2004API(options);
      window.API_1484_11.loadFromJSON(JSON.parse(meta));

      /** Подписываемся на обновление скорма */
      window.API_1484_11.on('SetValue.cmi.*', (CMIElement: any, value: any) => {
        if (CMIElement === 'cmi.completion_status' || CMIElement === 'cmi.core.lesson_status') {
          this.changeStatus(value);
        } else {
          this.update(window.API_1484_11?.cmi);
        }
      });

      /** Подписываемся на инициализацию скорма */
      window.API_1484_11.on('Initialize', () => {
        this.initialize();
      });

      this.apiLoaded();
    }

    /**
   * Инициализация Скорма AICC версии
   */
    private initScormAICC(meta: string, options: IScormOptions) {
      window.API = new AICC(options);
      window.API.loadFromJSON(JSON.parse(meta));

      /** Подписываемся на инициализацию скорма */
      window.API.on('LMSInitialize', () => {
        this.initialize();
      });

      /** Подписываемся на обновление скорма */
      window.API.on('LMSSetValue.cmi.*', (CMIElement: any, value: any) => {
        if (CMIElement === 'cmi.completion_status' || CMIElement === 'cmi.core.lesson_status') {
          this.changeStatus(value);
        } else {
          this.update(window.API_1484_11?.cmi);
        }
      });

      this.apiLoaded();
    }

    /**
   * Инициализация Скорма 12 версии
   */
    private initScorm12API(meta: string, options: IScormOptions) {
      window.API = new Scorm12API(options);
      window.API.loadFromJSON(JSON.parse(meta));

      /** Подписываемся на инициализацию скорма */
      window.API.on('LMSInitialize', () => {
        this.initialize();
      });

      /** Подписываемся на обновление скорма */
      window.API.on('LMSSetValue.cmi.*', (CMIElement: any, value: any) => {
        if (CMIElement === 'cmi.completion_status' || CMIElement === 'cmi.core.lesson_status') {
          this.changeStatus(value);
        } else {
          this.update(window.API?.cmi);
        }
      });

      this.apiLoaded();
    }
}
